

.reviewmanagebackground { 
	width:100vw;
	height:100vh;
	position:absolute;
	overflow:hidden;
	background-color: #FCFCF4;
}

.reviewmanagelogocontainer{
	width: 100%;
	height:15%;
	top:5%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}

.reviewmanagelogo{
	width: 40%;
	height: auto;
}

.reviewmanagecontext{
	width: 100%;
	height:60%;
	top:20%;
	position: absolute;
	overflow: auto;
}

.photocontainer{
	width: 90%;
	height: 150px;
	background-color:rgba(255, 255, 255, 1);
	position: relative;
	display: flex;
	justify-content: center;
}

.photocontentempty {
	justify-content: center;
	align-self: center;
}

.reviewmanagebottom {
	width: 100%;
	height:20%;
	bottom:0%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}

.reviewmanagecontainer { 
	background-color:rgba(255, 255, 255, 1);
	width:80%;
	height:auto;
	position:relative;
	left:10%;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border:2px solid rgba(0, 0, 0, 1);
	display: flex;
	flex-direction: column;
	align-items: first baseline;
	margin-bottom: 20px;
}

.reviewmanagenametag { 
	color:rgba(0, 0, 0, 1);
	width:80%;
	height:30px;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:25px;
	letter-spacing:0;
	margin-top: 15px;
	margin-left: 15px;
	margin-bottom: 10px;
}

.reviewmanagecontenttag { 
	color:rgba(0, 0, 0, 1);
	width:80%;
	height:15px;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:15px;
	letter-spacing:0;
	margin-bottom: 10px;
}

.reviewmanagereviewtag { 
	color:rgba(0, 0, 0, 1);
	width:90%;
	height:40px;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:15px;
	letter-spacing:0;
	margin-bottom: 10px;
}

.reviewcancelbutton { 
	background-color:rgba(242.00000077486038, 156.00000590085983, 150.0000062584877, 1);
	width:80%;
	height:30px;
	position:relative;
	align-self: center;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border:2px solid rgba(0, 0, 0, 1);
	margin-bottom: 20px;
}

.reviewmenufindbutton { 
	background-color:rgba(255, 255, 255, 1);
	width:80%;
	height:30px;
	position:relative;
	align-self: center;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border:2px solid rgba(0, 0, 0, 1);
	margin-bottom: 10px;
}

.reviewmanagebackbutton { 
	background-color:rgba(255, 255, 255, 1);
	width:80%;
	height:50px;
	position:absolute;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border:2px solid rgba(0, 0, 0, 1);
}



