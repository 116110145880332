
.threadbackground {
	width:100vw;
	height:100vh;
	position:absolute;
	overflow:hidden;
	background-color: #FCFCF4;
}

.threadcontext {
	width: 100%;
	height:80%;
	top:5%;
	position: absolute;
	overflow: auto;
}

.threadbottom{
	width: 100%;
	height:20%;
	bottom:0%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}

.threadbackbutton{
	background-color:rgba(255, 255, 255, 1);
	width:80%;
	height:50px;
	position:relative;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border:2px solid rgba(0, 0, 0, 1);
}

.threadtotalcontainer{
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
}

.threadUserHeader{
	width: 80%;
	display: flex;
	left: 10%;
	align-self: center;
	margin-bottom: 10px;
}

.threadUserIcon { 
	border-radius:500px;
	width:22px;
	height:21px;
	position:relative;
	background-image:url(images/ellipse_7.png);
	background-repeat:no-repeat;
	background-size:cover;
	border:2px solid rgba(0, 0, 0, 1);
	padding: 2px;
}

.threadusername { 
	color:rgba(0, 0, 0, 1);
	width:70%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:16px;
	letter-spacing:0;
	margin-left: 10px;
	align-self:flex-end
}

.threadblockcontainer { 
	background-color:rgba(255, 255, 255, 1);
	width:80%;
	position:relative;
	left:10%;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border:2px solid rgba(0, 0, 0, 1);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	margin-bottom: 20px;
}

.threadblockrestaurantname { 
	color:#000000;
	width:80%;
	height:33px;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:20px;
	margin-left: 5%;
	letter-spacing:0;
	margin-top: 20px;
}

.threadblocknameandheart {
	width: 100%;
	position: relative;
	display: flex;
}

.likeHeart { 
	width:23px;
	height:21px;
	position:relative;
	padding-top: 30px;
}

.threadblockrestaurantkeyword { 
	color:rgba(0, 0, 0, 1);
	width:90%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:13px;
	letter-spacing:0;
	margin-bottom: 3px;
}

.threadblockrestaurantstars {
	color:rgba(0, 0, 0, 1);
	width:90%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:13px;
	letter-spacing:0;
	align-self: center;
	margin-bottom: 10px;
}

.threadblockrestaurantcomment { 
	color:rgba(0, 0, 0, 1);
	width:90%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:15px;
	letter-spacing:0;
	margin-bottom: 30px;
}
