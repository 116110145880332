

.bookbackground { 
	width:100vw;
	height:100vh;
	position:absolute;
	overflow:hidden;
	background-color: #FCFCF4;
}

.booklogocontainer{
	width: 100%;
	height:15%;
	top:5%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}

.booklogo{
	width: 40%;
	height: auto;
}

.bookcontext{
	width: 100%;
	height:60%;
	top:20%;
	position: absolute;
	overflow: auto;
}

.bookbottom {
	width: 100%;
	height:20%;
	bottom:0%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}

.bookcontainer { 
	background-color:rgba(255, 255, 255, 1);
	width:80%;
	height:224px;
	position:relative;
	left:10%;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border:2px solid rgba(0, 0, 0, 1);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	margin-bottom: 20px;
}

.booknametag { 
	color:rgba(0, 0, 0, 1);
	width:80%;
	height:30px;
	position:relative;
	font-family:Inter;
	text-align:center;
	font-size:25px;
	letter-spacing:0;
	margin-top: 30px;
	margin-bottom: 30px;
}
.bookwaitingtag { 
	color:rgba(0, 0, 0, 1);
	width:80%;
	height:33px;
	position:relative;
	font-family:Inter;
	text-align:center;
	letter-spacing:0;
	margin-bottom: 20px;
}

.bookcancelbutton { 
	background-color:rgba(242.00000077486038, 156.00000590085983, 150.0000062584877, 1);
	width:80%;
	height:28px;
	position:relative;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border:2px solid rgba(0, 0, 0, 1);
	margin-bottom: 5px;
}

.bookmenufindbutton { 
	background-color:rgba(255, 255, 255, 1);
	width:80%;
	height:32px;
	position:relative;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border:2px solid rgba(0, 0, 0, 1);
	
}

.bookbackbutton { 
	background-color:rgba(255, 255, 255, 1);
	width:80%;
	height:50px;
	position:absolute;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border:2px solid rgba(0, 0, 0, 1);
}



