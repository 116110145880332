

.searchbackground { 
	width:100vw;
	height:100vh;
	position:absolute;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	background-image:url(images/rectangle_45.png);
	background-repeat:no-repeat;
	background-size:cover;
	overflow:hidden;
}

.searchtotalcontainer{
	width:79%;
	height:80%;
	display: flex;
	flex-direction: column;
	position:relative;
	left:10%;
	top:10%;
}

.searchcontentinnercontainer { 
	background-color:rgba(252.00000017881393, 252.00000017881393, 244.0000006556511, 1);
	width: 100%;
	height:100%;
	position:relative;
	border-top-left-radius:15px;
	border-top-right-radius:15px;
	border-bottom-left-radius:15px;
	border-bottom-right-radius:15px;
	border:3px solid rgba(0, 0, 0, 1);
	display: flex;
	flex-direction: column;
	align-content: first baseline;
	justify-content: baseline;
	overflow: hidden;
}

.searchcontentcontainer { 
	background-color:rgba(252.00000017881393, 252.00000017881393, 244.0000006556511, 1);
	width: 100%;
	height:85%;
	position:relative;
	display: flex;
	flex-direction: column;
	align-content: first baseline;
	justify-content: baseline;
	overflow: auto;
}

.searchrestaurantlogo { 
	width:80%;
	height:auto;
	position:relative;
	align-self: center;
	margin-top: 10%;
}

.searchnametag { 
	color:rgba(0, 0, 0, 1);
	width:80%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:25px;
	letter-spacing:0;
	margin-left: 10%;
	margin-top: 5%;
	margin-bottom: 5px;
}

.searchparttag { 
	color:rgba(0, 0, 0, 1);
	width:80%;
	position:relative;
	font-family:Inter;
	text-align:center;
	font-size:20px;
	letter-spacing:0;
	align-self: center;
	margin-top: 15%;
	margin-bottom: 10px;
}

.searchcontexttag { 
	color:rgba(0, 0, 0, 1);
	width:80%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:15px;
	letter-spacing:0;
	margin-left: 10%;
	
}

.searchrestaurantmap { 
	width:80%;
	height:auto;
	position:relative;
	align-self: center;
	margin-top: 10px;
	margin-bottom: 10px;
}

.restarantmenulist{
	width: 80%;
	position: relative;
	align-self: center;
	background-color:rgba(255, 255, 255, 1);
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border:2px solid rgba(0, 0, 0, 1);
	display: flex;
	flex-direction: column;
}

.restaurantcontexttag { 
	color:rgba(0, 0, 0, 1);
	width:80%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:15px;
	letter-spacing:0;
	margin-left: 10%;
	margin-top: 30px;
}

.restaurantcontextcontent {
	color:rgba(0, 0, 0, 1);
	width:80%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:15px;
	letter-spacing:0;
	margin-left: 10%;
}

.restaurantcontextcomment { 
	color:rgba(0, 0, 0, 1);
	width:80%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:15px;
	letter-spacing:0;
	margin-left: 10%;

}

.restaurantwaitingtag1 { 
	color:rgba(0, 0, 0, 1);
	width:80%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:15px;
	letter-spacing:0;
	margin-left: 10%;
	margin-top: 30px;
	margin-bottom: 10px;
}

.restaurantwaitingtag2 { 
	color:rgba(0, 0, 0, 1);
	width:80%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:15px;
	letter-spacing:0;
	margin-left: 10%;
	margin-bottom: 10px;
}

.restaurantwaitingtag3 { 
	color:rgba(0, 0, 0, 1);
	width:80%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:15px;
	letter-spacing:0;
	margin-left: 10%;
	margin-bottom: 10px;
}

.showallmenubutton{
	width: 90%;
	height: 30px;
	position: relative;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	font-size: 10px;
	align-self: center;
	margin-top: 30px;
	margin-bottom: 10px;
	background-color:rgba(255, 255, 255, 1);
}

.searchbackbutton { 
	background-color:rgba(255, 255, 255, 1);
	width:80%;
	height:40px;
	position:absolute;
	bottom:5%;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border:2px solid rgba(0, 0, 0, 1);
	align-self: center;
}

.changepageslide{
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.searchslidebutton { 
	background-color:rgba(255, 255, 255, 1);
	width:49%;
	height:40px;
	position:relative;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border:2px solid rgba(0, 0, 0, 1);
	align-self: center;
}



