
.alterbackground {
	width:100vw;
	height:100vh;
	position:absolute;
	overflow:hidden;
	background-color: #FCFCF4;
}

.altercontext {
	width: 100%;
	height:80%;
	top:7.5%;
	position: absolute;
	overflow: auto;
}

.alterbottom{
	width: 100%;
	height:15%;
	bottom:0%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}

.alterbackbutton{
	background-color:rgba(255, 255, 255, 1);
	width:80%;
	height:50px;
	position:relative;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border:2px solid rgba(0, 0, 0, 1);
}

.altertotalcontainer{
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.threadUserHeader{
	width: 80%;
	display: flex;
	left: 10%;
	align-self: center;
	margin-bottom: 10px;
}

.threadusername { 
	color:rgba(0, 0, 0, 1);
	width:70%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:16px;
	letter-spacing:0;
	margin-left: 10px;
	align-self:flex-end
}

.alterblockcontainer { 
	background-color:rgba(255, 255, 255, 1);
	width:80%;
	position:relative;
	left:10%;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border:2px solid rgba(0, 0, 0, 1);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
}

.alterblockrestaurantname { 
	color:#000000;
	width:80%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:20px;
	margin-left: 5%;
	letter-spacing:0;
	margin-top: 20px;
	align-self: baseline;
}

.category-container{
	width:90%;
	margin-bottom: 10px;
}

.alterblockrestaurantkeyword { 
	color:rgba(0, 0, 0, 1);
	width:90%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:13px;
	letter-spacing:0;
	margin-bottom: 10px;
	margin-right: 5px;
	align-self:flex-start;
}

.alterblockrestaurantopeninghour {
	color:rgba(0, 0, 0, 1);
	width:90%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:13px;
	letter-spacing:0;
	align-self: center;
	margin-bottom: 10px;
}

.alterblockrestaurantcomment { 
	color:rgba(0, 0, 0, 1);
	width:90%;
	position:relative;
	font-family:Inter;
	text-align:left;
	font-size:15px;
	letter-spacing:0;
	margin-bottom: 20px;
}
