.e294_42 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-image: url(images/rectangle_31.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.e403_16 {
  width: 90%;
  height: 116.0897445678711px;
  position: absolute;
  left: 5%;
  top: 31px;
}
#searchkey {
  z-index: 1;
  background-color: rgba(255, 255, 255, 1);
  width: 75%;
  height: 53px;
  position: absolute;
  left: 0%;
  top: 0%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 2px solid rgba(0, 0, 0, 1);
  font-size: medium;
  padding-left: 5%;
  padding-right: 20%;
}

.iconbox {
  z-index: 1;
  width: 55px;
  position: absolute;
  right: 0%;
  top: 0px;
  display: flex;
  flex-direction: column;
}
.searchbutton {
  z-index: 1;
  background-color: rgba(
    210.00000268220901,
    37.00000159442425,
    25.000000409781933,
    1
  );
  width: 55px;
  height: 53px;
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 3px solid rgba(0, 0, 0, 1);
}

.e313_8 {
  z-index: 1;
  width: 55px;
  height: 51px;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url(images/rectangle_139.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.mypagebutton {
  z-index: 1;
  background-color: rgba(255, 255, 255, 1);
  width: 54.16666793823242px;
  height: 50.089744567871094px;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 3px solid rgba(0, 0, 0, 1);
  margin-top: 15px;
}

.buttonform {
  z-index: 1;
  width: 60%;
  height: auto;
  position: absolute;
  justify-self: center;
  left: 19%;
  top: 19%;
}

#bottombutton {
  width: 80%;
  height: auto;
  position: absolute;
  right: 5%;
  bottom: 5%;
}

.bookbutton {
  z-index: 1;
  background-color: rgba(255, 255, 255, 1);
  width: 74px;
  height: 55px;
  position: absolute;
  top: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 1);
  font-size: larger;
}

#logo {
  z-index: 1;
  width: 50%;
  height: auto;
}

.GPSButton {
  left: 70%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 1);
  width: 74px;
  height: 55px;
  position: absolute;
  bottom: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 1);
}

/* .resInfo
{

} */
