#background {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FCFCF4;
    overflow: hidden; 
}

#loadinglogo {
    width: 60%;
    height: auto;
    display: block;
    left : 20% ;
}